import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import ButtonComponent from "./ButtonComponent";
import PriceFormComponent from "./PriceFormComponent";
import "../styles/PriceCardStyles.scss";

const Portal = ({ children }) => {
  const portalContainerRef = useRef(null);
  const [container] = useState(() => {
    const div = document.createElement("div");
    portalContainerRef.current = div;
    return div;
  });

  useEffect(() => {
    if (!portalContainerRef.current) {
      return;
    }

    document.body.appendChild(portalContainerRef.current);
    return () => {
      document.body.removeChild(portalContainerRef.current);
    };
  }, []);

  return container ? ReactDOM.createPortal(children, container) : null;
};

const styles = ["style-1", "style-2", "style-3"];

const PriceCard = ({
  title,
  cardName,
  description,
  price,
  buttonText,
  img,
  isDop,
}) => {
  const [hoveredStyle, setHoveredStyle] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getRandomStyle = () => {
    const randomIndex = Math.floor(Math.random() * styles.length);
    return styles[randomIndex];
  };

  const handleMouseEnter = () => {
    setHoveredStyle(getRandomStyle());
  };

  const handleMouseLeave = () => {
    setHoveredStyle(null);
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <Portal>
          <PriceFormComponent
            text1={cardName}
            text2={price}
            onClose={handleCloseModal}
          />
        </Portal>
      )}

      <div
        className={`price-card-container ${hoveredStyle}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={`price-card ${hoveredStyle}`}>
          <h3 className="price-card-title">{title}</h3>
          <img alt="card img" src={img} className="price-card-img" />
          <div className={!isDop ? 'price-card-name_small' : ''}>
            <p className="price-card-name">{cardName}</p>
            {isDop && (
              <p className="price-card-dop">Дополнение к базовому блоку</p>
            )}
          </div>
          <p className="price-card-description">{description}</p>
          <p className="price-card-price">{price}</p>
          <ButtonComponent
            className={`price-card-button button-style-1`}
            onClick={handleButtonClick}
          >
            {buttonText}
          </ButtonComponent>
        </div>
      </div>
    </>
  );
};

export default PriceCard;
