import React from 'react';
import '../styles/TextBlockStyles.scss';

const TextBlockLims = ({ text }) => {
  return (
    <div className="text-block-lims">
      <p><span>LIMS IT-Lab</span> – надежная автоматизация лабораторных процессов</p>
    </div>
  );
};

export default TextBlockLims;
