import objectIspitanyiImg from "../assets/cardImages/object_ispitanyi.jpg";
import smkImg from "../assets/cardImages/smk.jpg";
import vlkImg from "../assets/cardImages/vlk.jpg";
import graduir from "../assets/cardImages/graduir.jpg";
import protokolIsp from "../assets/cardImages/protokolIsp.jpg";
import reactiv from "../assets/cardImages/reactiv.jpg";
import baseBlock from "../assets/cardImages/baseBlock.jpg";
import minilis from "../assets/cardImages/minilis.jpg";

export const priceData = [
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Базовый блок» lims it-lab",
      description:
        "Включает в себя все необходимые комплектации для ведения отчетности и администрирования вашей лаборатории.",
      price: "от 287 500,00 руб.",
      buttonText: "Заказать",
      img: baseBlock,
      isDop: false,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«СМК» lims it-lab",
      description:
        "Цифровой блок СМК предназначен для облегчения задач ведения процессов, связанных с системой менеджмента в лаборатории.",
      price: "от 40 250,00 руб.",
      buttonText: "Заказать",
      img: smkImg,
      isDop: true,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«ВЛК» lims it-lab",
      description:
        "Комплекс мероприятий по обеспечению контроля качества лабораторных исследований и измерений.",
      price: "от 172 500,00 руб.",
      buttonText: "Заказать",
      img: vlkImg,
      isDop: true,
    },
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Градуировочные зависимости» lims it-lab",
      description:
        "Позволяет автоматизировать процесс построения градуировочных зависимостей, провести отбраковку и проверку получаемых значений",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: graduir,
      isDop: true,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Протоколы испытаний» lims it-lab",
      description:
        "Модуль предназначен для формирования итоговых отчетов по проведенным испытаниям.",
      price: "от 115 000,00 руб.",
      buttonText: "Заказать",
      img: protokolIsp,
      isDop: true,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«MiniLIS» lims it-lab Защита от подделок",
      description:
        "Онлайн портал ведения и контроля протоколов испытаний и технической оснащенности.",
      price: "от 2 400,00 руб.",
      buttonText: "Заказать",
      img: minilis,
      isDop: true,
    },
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Реактивы» lims it-lab",
      description:
        "Реактивы и расходные материалы лаборатории.",
      price: "от 17 250,00 руб.",
      buttonText: "Заказать",
      img: reactiv,
      isDop: true,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Объекты испытаний» lims it-lab",
      description:
        "Соблюдение прослеживания образцов и контроль процесса движения проб.",
      price: "от 115 000,00 руб.",
      buttonText: "Заказать",
      img: objectIspitanyiImg,
      isDop: true,
    },
  ],
];

export const priceDataTablet = [
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Базовый блок» lims it-lab",
      description:
        "Включает в себя все необходимые комплектации для ведения отчетности и администрирования вашей лаборатории.",
      price: "от 287 500,00 руб.",
      buttonText: "Заказать",
      img: baseBlock,
      isDop: false,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«СМК» lims it-lab",
      description:
        "Цифровой блок СМК предназначен для облегчения задач ведения процессов, связанных с системой менеджмента в лаборатории.",
      price: "от 40 250,00 руб.",
      buttonText: "Заказать",
      img: smkImg,
      isDop: true,
    },
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«ВЛК» lims it-lab",
      description:
        "Комплекс мероприятий по обеспечению контроля качества лабораторных исследований и измерений.",
      price: "от 172 500,00 руб.",
      buttonText: "Заказать",
      img: vlkImg,
      isDop: true,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Градуировочные зависимости» lims it-lab",
      description:
        "Позволяет автоматизировать процесс построения градуировочных зависимостей, провести отбраковку и проверку получаемых значений",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: graduir,
      isDop: true,
    },
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Протоколы испытаний» lims it-lab",
      description:
        "Модуль предназначен для формирования итоговых отчетов по проведенным испытаниям.",
      price: "от 115 000,00 руб.",
      buttonText: "Заказать",
      img: protokolIsp,
      isDop: true,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«MiniLIS» lims it-lab Защита от подделок",
      description:
        "Онлайн портал ведения и контроля протоколов испытаний и технической оснащенности.",
      price: "от 2 400,00 руб.",
      buttonText: "Заказать",
      img: minilis,
      isDop: true,
    },
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Реактивы» lims it-lab",
      description:
        "Реактивы и расходные материалы лаборатории.",
      price: "от 17 250,00 руб.",
      buttonText: "Заказать",
      img: reactiv,
      isDop: true,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Объекты испытаний» lims it-lab",
      description:
        "Соблюдение прослеживания образцов и контроль процесса движения проб.",
      price: "от 115 000,00 руб.",
      buttonText: "Заказать",
      img: objectIspitanyiImg,
      isDop: true,
    },
  ]
]

export const priceDataMobile = [
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Базовый блок» lims it-lab",
      description:
        "Включает в себя все необходимые комплектации для ведения отчетности и администрирования вашей лаборатории.",
      price: "от 287 500,00 руб.",
      buttonText: "Заказать",
      img: baseBlock,
      isDop: false,
    }
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«СМК» lims it-lab",
      description:
        "Цифровой блок СМК предназначен для облегчения задач ведения процессов, связанных с системой менеджмента в лаборатории.",
      price: "от 40 250,00 руб.",
      buttonText: "Заказать",
      img: smkImg,
      isDop: true,
    }
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«ВЛК» lims it-lab",
      description:
        "Комплекс мероприятий по обеспечению контроля качества лабораторных исследований и измерений.",
      price: "от 172 500,00 руб.",
      buttonText: "Заказать",
      img: vlkImg,
      isDop: true,
    }
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Градуировочные зависимости» lims it-lab",
      description:
        "Позволяет автоматизировать процесс построения градуировочных зависимостей, провести отбраковку и проверку получаемых значений",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: graduir,
      isDop: true,
    }
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Протоколы испытаний» lims it-lab",
      description:
        "Модуль предназначен для формирования итоговых отчетов по проведенным испытаниям.",
      price: "от 115 000,00 руб.",
      buttonText: "Заказать",
      img: protokolIsp,
      isDop: true,
    }
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«MiniLIS» lims it-lab Защита от подделок",
      description:
        "Онлайн портал ведения и контроля протоколов испытаний и технической оснащенности.",
      price: "от 2 400,00 руб.",
      buttonText: "Заказать",
      img: minilis,
      isDop: true,
    }
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Реактивы» lims it-lab",
      description:
        "Реактивы и расходные материалы лаборатории.",
      price: "от 17 250,00 руб.",
      buttonText: "Заказать",
      img: reactiv,
      isDop: true,
    }
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Объекты испытаний» lims it-lab",
      description:
        "Соблюдение прослеживания образцов и контроль процесса движения проб.",
      price: "от 115 000,00 руб.",
      buttonText: "Заказать",
      img: objectIspitanyiImg,
      isDop: true,
    }
  ]
]
