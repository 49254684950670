import React from "react";
import img from "../assets/promotion.jpg";

const PromotionSection = () => {
  return (
    <img
      src={img}
      alt="promotion"
      style={{ width: "100%", marginTop: "70px", zIndex: 2 }}
    />
  );
};

export default PromotionSection;
